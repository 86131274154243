import styled from 'styled-components'

const PreloaderStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--white);
    background: var(--blackRussian);
    z-index: 99999999;

    .loader-box {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 1;
        
        .loader-num {
            width: var(--sp4x);
        }

        .progress-bar {
            position: relative;
            width: 10em;
            height: 1px;
            margin: 0 var(--sp1x);

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: ${({ percent }) => `${percent}%`};
                height: 100%;
                background: var(--white);
            }
        }
    }
    
`

export default PreloaderStyle