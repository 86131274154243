import { memo as Memo, useEffect, useState, useRef } from 'react'
import gsap from 'gsap'

//* HOCs
import { withUIContext } from 'context'

//* Styles
import PreloaderStyle from './style'
import Text from 'components/common/Text'

const Preloader = Memo(({ preloader, disablePreloader }) => {
    //! Refs
    const contRef = useRef()

    //! States
    const [isFontsLoaded, setIsFontsLoaded] = useState(false)
    const [percent, setPercent] = useState(0)

    //! Did Mount
    useEffect(() => {
        let num = 0;

        document.fonts.ready.then(function () {
            setIsFontsLoaded(true)
        });

        function imgLoad(img) {
            let imgL = document.images.length;
            img.src = img.getAttribute("src");

            setTimeout(function () {
                setPercent(Math.ceil((imgL === 1 ? 1 : (num) / (imgL - 1)) * 100))
                num++;
                if (num < imgL) {
                    imgLoad(document.images[num]);
                }
            }, 50)
        }

        imgLoad(document.images[num]);
    }, [])

    //! Animate after end
    useEffect(() => {
        if (percent === 100) {
            gsap.to(contRef.current, { delay: 0.5, duration: 1, y: '-100%', onComplete: () => disablePreloader() })
        }
    }, [percent])

    return preloader && (
        <PreloaderStyle ref={contRef} percent={percent} className={`${percent === 100 ? 'hide' : ''}`} >
            {isFontsLoaded && <div className="loader-box">
                <Text text="Backbone" className="gilroyMedium" />
                <div className="progress-bar"></div>
                <div className="loader-num">{percent}%</div>
            </div>}
        </PreloaderStyle>
    )
})

export default withUIContext(Preloader, ['preloader', 'disablePreloader'])